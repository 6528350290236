//@import "~antd/dist/antd.less";
#root,
.app,
.app > div {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.ant-layout-header {
  color: #ffffff;
  padding: 0 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px !important;
  h3.ant-typography {
    color: #ffffff;
  }
}

.ant-form-item {
  margin-bottom: 13px !important;
}

.ant-upload-list-item {
  .ant-image {
    width: 100% !important;
  }
}

/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* 火狐 */
input {
  -moz-appearance: textfield;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.ant-table-fixed {
  table-layout: fixed !important;
}

.ant-card-body {
  padding: 12px !important;
}

.ant-card-head {
  padding: 0 12px !important;

  .ant-card-head-title {
    padding: 12px 0 !important;
  }
}
.ant-descriptions-header {
  margin-bottom: 0px !important;
}

.ant-table-wrapper {
  .ant-form-item {
    //margin: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ant-card {
  border-top: none !important;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
.ant-table-container {
  font-size: 13px;
}
.ant-card-body {
  font-size: 13px;
}

.ant-descriptions-item-label {
  font-size: 13px !important;
}
.ant-descriptions-item-content {
  font-size: 13px !important;
}
.ant-tabs-tab-btn {
  font-size: 13px !important;
}
.ant-btn {
  font-size: 13px !important;
}
.ant-form-item-label {
  font-size: 13px !important;
}
label {
  font-size: 12px;
}
// .ant-layout-content:nth-child(1) {
//   overflow: scroll;
// }
// .ant-tabs-content-holder {
//   overflow: scroll;
// }
.ant-table-sticky-scroll {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  top: 80%;
}
.ant-menu-submenu {
  z-index: 9999;
}

